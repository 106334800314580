<template>
	<div class="data-list-container main-list-view mt-6 ml-6">
		<div class="mb-4 mt-6 ml-6" style="font-size: 28px!important">{{$t('Currencies')}}</div>
		<p class="ml-6 mb-6">{{ $t('Manage default currencies for your event') }}</p>
		<currency-sidebar
			:isSidebarActive="addNewDataSidebar"
			:data="sidebarData"
			@closeSidebar="toggleDataSidebar"
			@add="insert"
		/>

		<vs-table
			ref="table"
			multiple
			search
			sst
			:pagination="false"
			:data="currencies"
			:max-items="limit"
			:total="totalCurrencies"
			v-model="selected"
			@search="onSearch"
			@sort="onSort"
			@change-page="changePage">
			<div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
				<div class="flex flex-wrap-reverse items-center data-list-btn-container">
					<!-- ADD NEW -->
					<div
						class="btn-add-new px-4 py-25 mb-4 mr-4 vs-button vs-button-success vs-button-filled rounded-lg cursor-pointer"
						@click="addNewData">
						<span class="">{{ $t('Add New') }}</span>
					</div>

					<!-- Bulk -->
						<vs-dropdown
							class="dd-actions cursor-pointer mr-4 mb-4"
							vs-trigger-click>
							<div
								class="px-4 py-25 shadow-drop vs-button vs-button-primary vs-button-filled rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center">
								<span class="mr-3">{{ $t('Bulk Data') }}</span>
								<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
							</div>
							<vs-dropdown-menu>
								<!-- Export -->
								<!-- <vs-dropdown-item>
									<div
										class="flex items-center"
										:class="{ 'is-loading': isExporting }"
										@click="listExport = true">
										<span>{{ $t('Export') }}</span>
									</div>
								</vs-dropdown-item> -->
								<!-- Import -->
								<vs-dropdown-item @click="dialogImport = true">
									<label
										class="flex items-center cursor-pointer"
										:class="{ 'is-loading': isImporting }">
										<span>{{ $t('Import') }}</span>
										<!-- <input type="file" class="hidden" @change="onImport"> -->
									</label>
								</vs-dropdown-item>
							</vs-dropdown-menu>
						</vs-dropdown>

					<!-- ACTION - DROPDOWN -->
					<vs-dropdown
						class="dd-actions cursor-pointer mr-4 mb-4 ml-auto"
						vs-trigger-click>
						<div
							class="px-4 py-25 shadow-drop vs-button vs-button-light rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center">
							<span class="mr-3">{{ $t('With Selected') }}</span>
							<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
						</div>

						<vs-dropdown-menu>
								<!-- Delete -->
								<vs-dropdown-item
									:disabled="selected.length === 0">
									<span class="flex items-center" @click="bulkDelete">
										<feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
										<span>{{ $t('Delete') }}</span>
									</span>
								</vs-dropdown-item>
						</vs-dropdown-menu>
					</vs-dropdown>

				</div>

				<!-- ITEMS PER PAGE -->
				<vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
					<div
						class="px-4 py-25 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
						<span class="mr-2">
							{{ page * limit - (limit - 1) }} - {{ currencies.length > 0 ? page * limit : currencies.length }} of {{ totalCurrencies }}
						</span>
						<feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
					</div>
					<!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
					<vs-dropdown-menu>
						<vs-dropdown-item @click="changeLimit(10)"><span>10</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(25)"><span>25</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(50)"><span>50</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(100)"><span>100</span></vs-dropdown-item>
						<vs-dropdown-item @click="changeLimit(200)"><span>200</span></vs-dropdown-item>
					</vs-dropdown-menu>
				</vs-dropdown>
			</div>

			<template slot="thead">
				<vs-th sort-key="name">{{ $t('Name') }}</vs-th>
				<vs-th sort-key="code">{{ $t('Code') }}</vs-th>
				<vs-th sort-key="country">{{ $t('Country') }}</vs-th>
				<vs-th>{{ $t('Action') }}</vs-th>
			</template>

			<template slot-scope="{data}">
				<tbody>
					<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
						<vs-td>
							<p class="font-medium truncate">{{ tr.name }}</p>
						</vs-td>

						<vs-td>
							<p>{{ tr.code }}</p>
						</vs-td>

						<vs-td>
							<p>{{ tr.country }}</p>
						</vs-td>

						<vs-td class="whitespace-no-wrap">
							<feather-icon
								icon="EditIcon"
								svgClasses="w-5 h-5 hover:text-primary stroke-current"
								@click.stop="editData(tr)"
							/>
							<feather-icon
								icon="TrashIcon"
								svgClasses="w-5 h-5 hover:text-danger stroke-current"
								class="ml-2"
								@click.stop="deleteData(tr)"
							/>
						</vs-td>
					</vs-tr>
				</tbody>
			</template>
		</vs-table>
		<!-- export item -->
		<vs-popup
			style="color:rgb(255,255,255);"
			background-color="rgba(255,255,255,.6)"
			:title="$t('Export')"
			:active.sync="listExport">
			<p class="text-base text-black mb-5">
				{{ $t('Please select in what file format you want to export in this data') }}:
			</p>
			<div class="p-2">
				<div
					class="cursor-pointer"
					:class="{ 'is-loading': isExporting }"
					@click="exportCurrencies('excel')">
					<img src="@/assets/images/file-icons/xls.png" alt="xls" width="20px;"/>
					<span class="ml-2 text-base item-text-export text-black">{{ $t('Excel File') }}</span>
				</div>
			</div>
			<div class="p-2">
				<div
					class="cursor-pointer"
					:class="{ 'is-loading': isExporting }"
					@click="exportCurrencies('docx')">
					<img src="@/assets/images/file-icons/doc.png" alt="xls" width="20px;"/>
					<span class="ml-2 text-base item-text-export text-black">{{ $t('Document File') }}</span>
				</div>
			</div>
			<div class="p-2">
				<div
					class="cursor-pointer"
					:class="{ 'is-loading': isExporting }"
					@click="exportCurrencies('pdf')">
					<img src="@/assets/images/file-icons/pdf.png" alt="xls" width="20px;"/>
					<span class="ml-2 text-base item-text-export text-black">{{ $t('PDF File') }}</span>
				</div>
			</div>
		</vs-popup>
		<!-- import item -->
		<vs-prompt
			class="import"
			:accept-text="$t('Start Import')"
			:is-valid="tmpCurrencies.length > 0"
			:cancel-text="$t('Cancel')"
			@accept="createBulk"
			@close="closeDialogImport"
			@cancel="closeDialogImport"
			style="color:rgb(255,255,255);"
			background-color="rgba(255,255,255,.6)"
			:title="$t('Import Data')"
			:active.sync="dialogImport">
			<div class="modal-import-box">
				<div>
					<vs-row vs-type="flex" vs-justify="center">
						<vue-xlsx :isShowFileXlsx="dialogImport" @on-select-file="handleImportBulk" @drop.prevent="handleImportBulk">
								{{ $t('Select File') }}
						</vue-xlsx>
					</vs-row>
					<div class="text-base text-grey" mb-5>{{ $t('All data will be imported, similar data will be ignored') }}</div>
				</div>
			</div>
			<div class="title-download-import">
				{{ $t('Download Template For Import') }}
			</div>
			<div class="item-download-import">
				<a href="./../../template-xlsx/Template_Currencies_Kobla.csv" download>{{ $t('Download') }} CSV Template - {{ $t('Currencies') }}</a>
			</div>
			<div class="item-download-import">
				<a href="./../../template-xlsx/Template_Currencies_Kobla.xlsx" download>{{ $t('Download') }} Excel Template - {{ $t('Currencies') }}</a>
			</div>
		</vs-prompt>
		<!-- Pagination -->
		<vs-pagination
			class="mt-3"
			:total="totalPages"
			:max-items="limit"
			:size-array="totalCurrencies"
			v-model="page">
		</vs-pagination>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueXlsx from '@/components/excel/ImportExcel.vue';
import CurrencySidebar from '@/components/CurrencySidebar.vue';
import {
  duplicateVar,
  delay,
  getAxiosErrorMessage,
  downloadBlob,
} from '@/lib/helper';
import currenciesApi from '@/api/currency';

export default {
  name: 'Currencies',
  components: {
    CurrencySidebar,
    VueXlsx,
  },
  data() {
    return {
      keyword: '',
      currencies: [],
      tmpCurrencies: [],
      isFetching: false,
      isAll: false,
      page: 1,
      limit: 10,
      orderBy: 'created_at',
      sortBy: 'asc',
      totalPages: 0,
      totalCurrencies: 0,
      selected: [],
      selectedItem: null,
      isFormVisible: false,
      isDeleteVisible: false,
      isDeleting: false,
      isMounted: false,
      addNewDataSidebar: false,
      AddNewDataDialogBroadcast: false,
      sidebarData: {},
      dialogDataBroadcast: {},
      isImporting: false,
      listExport: false,
      dialogImport: false,
      isExporting: false,
    };
  },
  methods: {
    fetch(reset) {
      this.$vs.loading();
      this.isFetching = true;
      if (reset) {
        this.page = 1;
        this.currencies = [];
      }
      const keyword = this.keyword;
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
      };
      if (keyword !== '') {
        params.keyword = keyword;
      }
      const callback = (response) => {
        const currencies = response.data;
        const lastPage = response.last_page;
        this.currencies = currencies;
        this.isAll = currencies.length === 0 || currencies.length < this.limit;
        // this.page++;
        if (lastPage) {
          this.totalPages = lastPage;
        }
        this.totalCurrencies = response.total;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Categories'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
        this.$vs.loading.close();
      };
      currenciesApi.getCurrencies(params, callback, errorCallback);
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    deleteData(item) {
      this.selectedItem = duplicateVar(item);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')} "${item.name}"?`,
        accept: this.deleteRecord,
        acceptText: this.$t('Delete'),
        cancelText: this.$t('Cancel'),
      });
    },
    bulkDelete() {
      const total = this.selected.length;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: `${this.$t('Are you sure want to delete')}  ${total} ${this.$t('Currencies')}?`,
        accept: this.deleteRecords,
        acceptText: this.$t('Delete'),
        cancelText: this.$t('Cancel'),
      });
    },
    showCustomFields() {
      this.isCustomFieldsVisible = true;
    },
    editData(data) {
      this.selectedItem = duplicateVar(data);
      this.sidebarData = duplicateVar(data);
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    onSearch(keyword) {
      delay(() => {
        this.keyword = keyword ? keyword.trim() : '';
        this.fetch(true);
      }, 1000);
    },
    onSort(sortKey, sortType) {
      this.orderBy = sortKey;
      this.sortBy = sortType;
      if (this.orderBy && this.sortBy) this.fetch(true);
    },
    deleteRecord() {
      this.$vs.loading();
      const item = this.selectedItem;
      const callback = (response) => {
        this.remove(item.id);
        const message = response.message;
        this.$vs.notify({
          title: this.$t('Currency'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Currency'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$vs.loading.close();
      };
      currenciesApi.delete(item.id, callback, errorCallback);
    },
    deleteRecords() {
      const ids = this.selected.map(({ id }) => id);
      const callback = (response) => {
        ids.forEach((id) => {
          this.remove(id);
        });

        const message = response.message;
        this.$vs.notify({
          title: this.$t('Currency'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Currency'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };
      currenciesApi.bulkDelete(ids, callback, errorCallback);
    },
    insert(currencies) {
      const index = this.currencies.findIndex(({ id }) => id === currencies.id);
      if (index === -1) {
        this.currencies.push(currencies);
        this.totalCurrencies++;
      } else {
        this.$set(this.currencies, index, currencies);
      }
    },
    remove(itemId) {
      const index = this.currencies.findIndex(({ id }) => id === itemId);
      if (index !== -1) {
        this.currencies.splice(index, 1);
        this.totalCurrencies--;
      }
    },
    changeLimit(value) {
      this.limit = value;
      this.fetch(true);
    },
    changePage(page) {
      this.page = page;
    },
    onSelectAll(event) {
      const elem = event.target;
      const isCheckbox = elem.classList.contains('vs-checkbox--input');
      const isCheckAll = !!elem.closest('.vs-table--thead');
      if (isCheckbox && isCheckAll) {
        const isChecked = elem.checked;
        if (isChecked) {
          this.selected = this.currencies;
        } else {
          this.selected = [];
        }
      }
    },

    closeDialogImport() {
      this.showFileImport = false;
    },
    handleImportBulk(excelDataConverted) {
      const excelData = duplicateVar(excelDataConverted.body);
      this.tmpCurrencies = excelData;
    },
    createBulk() {
      this.$vs.loading({ type: 'point' });
      this.isImporting = true;
      const notifTitle = this.$t('Import Currencies');
      const callback = (response) => {
        const data = response.data;
        const message = response.message;
        const newCurrencies = data;

        newCurrencies.forEach((item) => {
          this.insert(item);
        });


        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
        this.isImporting = false;
        this.tmpCurrencies = [];
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isImporting = false;
      };
      currenciesApi.bulkCreate((this.tmpCurrencies), callback, errorCallback);
    },
    AddNewBroadcast() {
      this.dialogDataBroadcast = {};
      this.toggleDataDialogBroadcast(true);
    },
    toggleDataDialogBroadcast(val = false) {
      this.AddNewDataDialogBroadcast = val;
    },
    exportCurrencies(type) {
      this.isExporting = true;
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
      };
      const callback = (response) => {
        const contents = response;
        downloadBlob(contents, { name: `${this.$t('Currencies')}`, type });
        this.isExporting = false;
      };
      const errorCallback = () => {
        this.isExporting = false;
      };
      currenciesApi.exportList(type, params, callback, errorCallback);
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
  },
  created() {
    this.fetch();
    document.addEventListener('click', this.onSelectAll);
  },
  destroyed() {
    document.removeEventListener('click', this.onSelectAll);
  },
  mounted() {
    this.isMounted = true;
  },
  watch: {
    page() {
      this.fetch();
    },
  },
};
</script>

<style lang="scss">
</style>
